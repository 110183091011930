import service from '@/utils/request';
export var getAgentUserWithdrawList = function getAgentUserWithdrawList(params) {
  return service({
    url: "/admin/tx_list",
    method: 'get',
    params: params
  });
};
export var auditAgentUserWithdraw = function auditAgentUserWithdraw(data) {
  return service({
    url: "/admin/tx_audit",
    method: 'post',
    data: data
  });
};